<template>
  <div class="loading">
    <div class="spinner spinner--dark"></div>
  </div>
</template>

<script>
export default {
  name: "Loading",
}
</script>
